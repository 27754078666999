import React from 'react';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import SellIcon from '@mui/icons-material/Sell';
import CampaignIcon from '@mui/icons-material/Campaign';
import InsightsIcon from '@mui/icons-material/Insights';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import BlockIcon from '@mui/icons-material/Block';
import FavoriteIcon from '@mui/icons-material/Favorite';
import CelebrationIcon from '@mui/icons-material/Celebration';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import AccessAlarmIcon from '@mui/icons-material/AccessAlarm';

import { DealPostType } from '@types';
import colors from '@utils/colors';
import { getCurrentDealEventStrObj } from '@utils/index';

const currentEventObj = getCurrentDealEventStrObj();

const largeStyle = {
  color: '#FFF',
  verticalAlign: 'middle',
  marginBottom: '2px',
  marginLeft: '8px'
};

const smallStyle = {
  color: '#FFF',
  verticalAlign: 'middle',
  marginBottom: '2px',
  marginRight: '8px',
  fontSize: '1rem'
};

type RibbonType =
  | 'SUBSCRIBE_SAVE'
  | 'TRENDING'
  | 'LIVE_EVENT'
  | 'EXPIRED'
  | 'PROMOTION'
  | 'NO_RIBBON'
  | 'BELOW_AVERAGE'
  | 'FAVORITE'
  | 'HIGHLY_REVIEWED'
  | 'FINAL_HOURS'
  | 'COUPON';

export const getRibbonColor = (
  ribbonType: RibbonType,
  isDarkMode: boolean
): string => {
  const ribbonColors: Record<RibbonType, string> = {
    TRENDING: isDarkMode ? colors.trendingRibbonDark : colors.trending,
    FAVORITE: isDarkMode ? colors.favoriteRibbonDark : colors.favorite,
    LIVE_EVENT: currentEventObj?.dealHighlightColor || colors.jdbPurple,
    SUBSCRIBE_SAVE: isDarkMode
      ? colors.subscribeSaveDark
      : colors.subscribeSave,
    // EARLY_PRIME_DAY: colors.earlyEventDeal,
    COUPON: isDarkMode ? colors.couponDark : colors.coupon,
    PROMOTION: colors.extraDeal,
    BELOW_AVERAGE: colors.belowAverage,
    HIGHLY_REVIEWED: colors.highReview,
    EXPIRED: colors.expired,
    FINAL_HOURS: '#C70039',
    NO_RIBBON: 'transparent'
  };

  return ribbonColors[ribbonType];
};

export const getRibbonDisplayName = (ribbonType: RibbonType) => {
  switch (ribbonType) {
    case 'FINAL_HOURS':
      return 'Final Hours';
    case 'TRENDING':
      return 'Popular';
    case 'FAVORITE':
      return 'Favorite';
    case 'LIVE_EVENT':
      return `${
        currentEventObj?.overrideRibbonLabel || currentEventObj?.label || ''
      }`;
    case 'SUBSCRIBE_SAVE':
      return 'Subscribe & Save';
    case 'COUPON':
      return 'Coupon Available';
    case 'PROMOTION':
      return 'Promotion Available';
    case 'BELOW_AVERAGE':
      return 'Lowest price';
    case 'HIGHLY_REVIEWED':
      return 'Highly reviewed';
    case 'EXPIRED':
      return 'Deal Price No Longer Available';
    case 'NO_RIBBON':
    default:
      return '';
  }
};

export const getRibbonTooltipTitle = (ribbonType: RibbonType) => {
  switch (ribbonType) {
    case 'FINAL_HOURS':
      return 'This deal is ending in the next few hours';
    case 'TRENDING':
      return 'This deal is popular in the past week according to our internal data';
    case 'FAVORITE':
      return 'This deal is one of your favorites';
    case 'LIVE_EVENT':
      return `${
        currentEventObj?.overrideRibbonTitle ||
        currentEventObj?.overrideRibbonLabel ||
        currentEventObj?.label ||
        ''
      }`;
    case 'SUBSCRIBE_SAVE':
      return 'This deal is eligible for Subscribe & Save';
    case 'COUPON':
      return 'This deal has a coupon available';
    case 'PROMOTION':
      return 'This deal has a promotion available';
    case 'BELOW_AVERAGE':
      return 'This deal is at its lowest price we have seen';
    case 'HIGHLY_REVIEWED':
      return 'This deal has a high review rating';
    case 'EXPIRED':
      return 'This deal is no longer available at the deal price';
    case 'NO_RIBBON':
    default:
      return '';
  }
};

export const renderRibbonIcon = (
  ribbonType: RibbonType,
  large: boolean = true
): React.ReactNode => {
  const props = {
    sx: large ? largeStyle : smallStyle,
    size: large ? 'small' : 'medium'
  };

  const EventIcon = currentEventObj?.Icon || CelebrationIcon;

  const icons: Record<RibbonType, React.ReactNode> = {
    LIVE_EVENT: <EventIcon {...props} />,
    FINAL_HOURS: <AccessAlarmIcon {...props} />,
    SUBSCRIBE_SAVE: <AutorenewIcon {...props} />,
    COUPON: <SellIcon {...props} />,
    FAVORITE: <FavoriteIcon {...props} />,
    PROMOTION: <CampaignIcon {...props} />,
    TRENDING: <InsightsIcon {...props} />,
    BELOW_AVERAGE: <TrendingDownIcon {...props} />,
    HIGHLY_REVIEWED: <ThumbUpIcon {...props} />,
    EXPIRED: <BlockIcon {...props} />,
    NO_RIBBON: null
  };

  return icons[ribbonType];
};

export const getRibbonType = (
  dealSummary: DealPostType | null,
  currentPromotionLinks: string[],
  topDeals: DealPostType[],
  isFavorite: boolean = false,
  onlyShowImportant: boolean = false
): RibbonType => {
  const shouldDisplayLiveEvent =
    currentEventObj &&
    (dealSummary?.listingMode || '')
      .toLowerCase()
      .includes(currentEventObj?.listingMode?.toLowerCase());

  if (shouldDisplayLiveEvent && currentEventObj?.shouldShowDeals) {
    return dealSummary?.listingMode.includes('FINAL_HOURS')
      ? 'FINAL_HOURS'
      : 'LIVE_EVENT';
  }
  // if (shouldDisplayEarlyPrimeDay) return 'EARLY_PRIME_DAY';
  if (!dealSummary?.finalPrice || !!dealSummary?.isExpired) return 'EXPIRED';
  if (isFavorite) return 'FAVORITE';

  const isTopDeal = topDeals.some(
    (topDeal) => topDeal.ASIN === dealSummary?.ASIN
  );
  if (isTopDeal) return 'TRENDING';

  if (onlyShowImportant) return 'NO_RIBBON';

  const isExtraDealLinkApproved = currentPromotionLinks.includes(
    dealSummary?.extraDealLink
  );
  if (isExtraDealLinkApproved) return 'PROMOTION';

  if (dealSummary?.averagePrice) return 'BELOW_AVERAGE';

  if (dealSummary?.reviewStars && dealSummary?.reviewStars > 4.3)
    return 'HIGHLY_REVIEWED';

  return 'NO_RIBBON';
};
